import React from 'react';
import {Container} from "react-bootstrap";

const WhoWeAre = ({aboutRef}) => {
    return (
        <div ref={aboutRef} className={'text-center text-dark bg-white second-bg'} style={{minHeight: `${window.innerWidth / 2.1333333333}px`}}>
            <Container className={'py-5'}>
            <p className={'opacity-50 mb-3'}>About us</p>
            <h1 className={'mb-5 fw-bold'} style={{color: '#042552'}}>Who we are?</h1>
            <p className={'fw-lighter lh-lg'} style={{color: '#042552'}}>Our company has rapidly expanded over the last 5 years, establishing a strong presence in various regions worldwide.
                We have a big team of employees and partners who contribute to our growth and success. We specialize in advertising and producing our own products, as well as providing advertising services through multiple partners. We prioritize our customers' convenience and enjoyment during their shopping experience, saving them time and effort by eliminating the need to search for products throughout the city. Our global network of offices, warehouses, and courier services ensures that we can promptly process customer orders and deliver products in the shortest time possible. At our company, you can discover a diverse range of exclusive and beneficial products.</p>
            </Container>
        </div>
    );
};

export default WhoWeAre;