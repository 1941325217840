import React, {useRef} from 'react';
import NavBar from "../Components/NavBar";
import {Container} from "react-bootstrap";
import FirstPage from "../Components/FirstPage";
import WhoWeAre from "../Components/WhoWeAre";
import Products from "../Components/Products";
import Slider from "../Components/Slider/Slider";
import FormComponent from "../Components/FormComponent";

const Main = () => {
    const aboutRef = useRef()
    const productsRef = useRef()
    const contactRef = useRef()

    const scroll = (ref) => {
        ref.current.scrollIntoView()
    }

    const scrollToContact = () => {
        contactRef.current.scrollIntoView()
    }

    return (
        <main className={'w-100 position-relative'}>
            <div className={'w-100 position-absolute top-0'}>
                <NavBar scroll={scroll} aboutRef={aboutRef} productsRef={productsRef} contactRef={contactRef} />
            </div>
            <div>
                <FirstPage scroll={scroll} contactRef={contactRef} aboutRef={aboutRef} />
            </div>
            <div ref={productsRef}>
                <Products scrollToContact={scrollToContact}/>
            </div>
            <div ref={contactRef}>
                <FormComponent />
            </div>
        </main>
    );
};

export default Main;