import React, {useState} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import WhoWeAre from "./WhoWeAre";

const FirstPage = ({aboutRef, scroll, contactRef}) => {

    return (
        <div className={'first-page-bg'}>
            <div className={'vh-100 d-flex align-items-end align-items-lg-center pb-5 pb-lg-0'}>
                <Container className={''}>
                    <Row>
                        <Col sm={12} lg={5} className={`d-flex flex-column text-lg-start mb-5 pb-3 pb-md-0 mb-lg-0`} style={{color: '#042552'}}>
                            <h1 className={'display-3 mb-3'} style={{fontWeight: 400}}>Plumbill is the <br /> <strong style={{fontWeight: 700}}>best solution</strong> <br />for your business</h1>
                            <span className={`${window.innerWidth < 992 ? 'small' : ''}`} style={{opacity: '80%'}}>Get more traffic. Acquire more customers. {window.innerWidth < 992 ? <br /> : ''}Produce and sell more stuff.</span>
                            <span className={`${window.innerWidth < 992 ? 'small' : ''}`} style={{opacity: '80%'}}>Plumbill works for businesses of all sizes.</span>
                            <div className={'mt-4'}>
                                <Button variant={'success'} className={'text-white rounded-pill px-5 py-3'} onClick={() => {scroll(contactRef)}}>Contact us</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <WhoWeAre aboutRef={aboutRef} />
        </div>
    );
};

export default FirstPage;