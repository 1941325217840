import './App.css';
import Main from "./Elements/Main";

function App() {
  return (
    <Main />
  );
}

export default App;
