import React, {useState} from 'react';
import {Button, Card} from "react-bootstrap";
import ModalMore from "./ModalMore";

const Product = ({product, scrollToContact}) => {
    const [show, setShow] = useState(false)

    const close = () => {
        setShow(false)
    }

    const closeAndScroll = () => {
        setShow(false)
        scrollToContact()
    }

    return (
        <Card className={'border-0 shadow-product me-3'} style={{width: '300px'}}>
            <Card.Body className={'d-flex flex-column justify-content-center'}>
                <img className={'mb-4'} src={product?.img} alt="" height={166} style={{objectFit: 'contain'}} />
                <div className={'m-0 p-0 mx-4 mb-4'} style={{borderBottom: '1px solid #e7eefd'}}/>
                <p className={'h4 mb-2'}>{product.title}</p>
                <span className={'opacity-50 mb-4 small'}>{product.miniDescription}</span>
                <div>
                    <Button size={'lg'} variant={"outline-success"} className={'rounded-pill px-5 py-3'} onClick={() => setShow(true)}>Learn more</Button>
                </div>
            </Card.Body>
            <ModalMore show={show} close={close} product={product} closeAndScroll={closeAndScroll} />
        </Card>
    );
};

export default Product;