import React, {useEffect, useRef, useState} from 'react';
import {Container} from "react-bootstrap";
import Slider from "./Slider/Slider";

const Products = ({scrollToContact}) => {
    let omega3 = 'https://plumbill.com/images/omega3.png';
    let gluco = 'https://plumbill.com/images/gluco.png';
    let oil = 'https://plumbill.com/images/oil.png';
    let protein = 'https://plumbill.com/images/protein.png';
    let prostaton = 'https://plumbill.com/images/prostaton.png';

    const [products, setProducts] = useState([
        {title: 'Omega 3', header: 'Low Oudor + High Absorbtion Krill Oil', miniDescription: 'Dietary supplement', description: 'Natural Form Omega 3 capsules are made of natural fish oil concentrate manufactured under strict quality control standards. Natural Form Omega 3 capsules provide nutritional support for your heart, cellular and metabolic health. Naturally occurring in fish, Omega 3 fatty acids are considered “good” fats and help to maintain triglyceride levels already within a normal range. Natural Form Omega 3 capsules make it easy to get the fish oil you want without the strong unpleasant fishy aftertaste.', img: omega3},
        {title: 'Prostaton', header: 'Men’s Prostate Health Supplement', miniDescription: 'Dietary supplement', description: 'As a man ages, maintaining a healthy prostate is key. This next-generation prostate health formula contains a broad array of ingredients to promote healthy prostate function and support normal urine flow. Botanical extracts like saw palmetto, nettle root, lycopene and more make Prostaton the most comprehensive prostate health supplement anywhere.', img: prostaton},
        {title: 'GlucoFlex', header: 'Supports Glucose & Energy Metabolism', miniDescription: 'Dietary supplement', description: 'GlucoFlex by Nutrol is a comprehensive combination of ingredients that supports glucose metabolism and provide antioxidant protection. The formula contains alpha lipoic acid to support blood glucose and energy metabolism. It includes resveratrol for cardiovascular health, and magnesium for glucose metabolism and energy production. Plus, banaba, gymnema, bitter melon, and eucalyptus are herbal extracts supporting blood glucose metabolism and transport.', img: gluco},
        {title: 'Hair Growth Oil', header: 'Natural Premium Hair Oil', miniDescription: 'Beauty & Personal Care', description: 'Powerful 100% natural castor oil  are made with only the finest quality ingredients, providing professional performance, helps accelerate blood flow to the scalp to increase hair growth. Helps thicken and volumize this, lifeless hair. Can be used on all types of hair including color treated, relaxed, and braided.', img: oil},
        {title: 'Protein Powder', header: 'Premium Whey Protein Blend', miniDescription: 'Dietary supplement', description: 'Muscle Man Protein Powder is the ideal post workout protein supplement, featuring 100% premium Whey. This gluten free protein powder formula with vitamins C & D plus zinc helps support your immune system and gives you the power to train harder and rebuild lean muscle faster because tomorrow\'s workout starts with today\'s recovery. Whey is the preferred protein in sports nutrition as it quickly absorbs and gets to your muscles and because of its high content of branched chain amino acids. BCAAs are key essential amino acids that help to activate muscle building after a hard workout.', img: protein},
    ])
    const [actProducts, setActProducts] = useState([])

    useEffect(() => {
        if (window.innerWidth < 768) {
            let p = products.map(prod => [prod])
            setActProducts(p)
        } else if (window.innerWidth <= 1050) {
            let p = [products.slice(0,2), products.slice(2,4), products.slice(4)]
            setActProducts(p)
        } else {
            let p = [products.slice(0,3), products.slice(3,5)]
            setActProducts(p)
        }
    }, [window.innerWidth])

    return (
        <div className={'text-center py-5'} style={{backgroundColor: '#FCFCFF'}}>
            <Container className={'justify-content-center'}>
                <p className={'opacity-50 mb-3'}>Our products</p>
                <h1 className={'mb-5 fw-bold'} style={{color: '#042552'}}>Best offers</h1>
                <p className={'fw-lighter lh-lg mb-5'} style={{color: '#042552'}}>Our company specializes in the manufacture of dietary supplements with a unique design and recipe that is carefully crafted to meet the nutritional needs of our customers. All products have certificates of quality and compliance with standards. Our supplements are made from high-quality ingredients and are manufactured in state-of-the-art facilities to ensure their purity and potency. We understand that everyone's dietary requirements are different, which is why we offer a range of products that cater to various health needs. We take pride in our ability to deliver products that not only meet but exceed our customers expectations. As an advertiser of our own products, we specialize in promoting our supplements in different countries. We work hard to ensure that our supplements are accessible to everyone who needs them,
                    no matter where they are in the world. If you're looking for food supplements that are effective, affordable, and backed by a company that cares,
                    look at our unique and innovative products.</p>
                <Slider height={'100%'} width={'100%'} autoPlay={false} autoPlayTime={0} items={actProducts} scrollToContact={scrollToContact} />
            </Container>
        </div>
    );
};

export default Products;