import React, { useContext } from "react";
import { SliderContext } from "./Slider";

import "./Styles.css";
import {Button} from "react-bootstrap";

export default function Arrows() {
    const { changeSlide } = useContext(SliderContext);

    return (
        <div className="arrows mt-5">
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Button variant={'outline-success'} className={'px-2 py-1 me-3'} onClick={() => changeSlide(-1)}>
                <i className={'bi bi-arrow-left-short h2'}></i>
            </Button>
            <Button variant={'outline-success'} className={'px-2 py-1'} onClick={() => changeSlide(1)}>
                <i className={'bi bi-arrow-right-short h2'}></i>
            </Button>
        </div>
    );
}