import React from 'react';
import {Button, Modal} from "react-bootstrap";

const ModalMore = ({product, show, close, closeAndScroll}) => {
    return (
        <Modal show={show} onHide={close} size={'lg'}>
            <Modal.Body className={'text-center py-5 px-md-5'}>
                <div>
                        <img src={product?.img} height={300} className={'mb-5 w-100'} style={{objectFit: 'contain'}} alt=""/>
                    <div className={'m-0 p-0 mx-5 mb-4'} style={{borderBottom: '1px solid #e7eefd'}}/>
                <h1 className={'mb-1'}>{product.title}</h1>
                    <p className={'mb-3'} style={{color: '#042552'}}>{product.header}</p>
                    <p className={'mb-3 opacity-50 small'} style={{color: '#042552'}}>{product.miniDescription}</p>
                    <p className={'mb-5 opacity-75'} style={{color: '#4F6586', fontWeight: '500'}}>{product.description}</p>
                <Button variant={'outline-success'} className={'rounded-pill py2 px-5'} onClick={closeAndScroll}>Contact us</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalMore;