import React, {useState} from 'react';
import {Col, Row, Form, Container, Button} from "react-bootstrap";
import {useForm, ValidationError} from "@formspree/react";

const FormComponent = () => {
    const [state, handleSubmit] = useForm("mknaznbw");
    const [name, setName] = useState('')
    const [validName, setValidName] = useState(false)
    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [comment, setComment] = useState('')
    const [validComment, setValidComment] = useState(false)

    if (state.succeeded) {

    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        handleSubmit(e).then(res => {
            console.log(res)
            if (res?.response?.status === 200 || res?.response?.statusText === 'OK') {
                setName('')
                setPhone('')
                setEmail('')
                setComment('')
                setValidName(true)
                setValidPhone(true)
                setValidEmail(true)
                setValidComment(true)
                setTimeout(() => setValidName(false), 3000)
                setTimeout(() => setValidPhone(false), 3000)
                setTimeout(() => setValidEmail(false), 3000)
                setTimeout(() => setValidComment(false), 3000)
            }
        })
    }

    const handleName = (e) => {
        setName(e.target.value)
    }
    const handlePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleComment = (e) => {
        setComment(e.target.value)
    }

    return (
        <div className={'form-bg pt-5 pb-4'}>
            <Container>
                <Row>
                    <Col sm={12} lg={6} className={'bg-transparent px-5 text-white mb-5 mb-lg-0'}>
                        <p className={'fw-light p-0 mb-3'}>Contact us</p>
                        <h1 className={'mb-5'}>Do you want to work <br/>
                            <strong className={'fw-bolder'}>with us?</strong></h1>
                        <Form onSubmit={handleSubmitForm}>
                            <Form.FloatingLabel label={'Full name'} className={'text-white bg-opacity-25'}>
                                <Form.Control required placeholder={'Full name'} name={'name'} value={name} onChange={handleName} className={`bg-white bg-opacity-25 text-white mb-4 ${validName && 'is-valid'}`} />
                            </Form.FloatingLabel>
                            <Form.FloatingLabel label={'Phone'} className={'text-white bg-opacity-25'}>
                                <Form.Control required placeholder={'Phone'} name={'phone'} value={phone} onChange={handlePhone} className={`bg-white bg-opacity-25 text-white mb-4 ${validPhone && 'is-valid'}`} />
                            </Form.FloatingLabel>
                            <Form.FloatingLabel label={'Email'} className={'text-white bg-opacity-25'}>
                                <Form.Control placeholder={'Email'} name={'email'} value={email} onChange={handleEmail} className={`bg-white bg-opacity-25 text-white mb-4 ${validEmail && 'is-valid'}`} />
                            </Form.FloatingLabel>
                            <Form.FloatingLabel label={'Write your comment'} className={'text-white bg-opacity-25'}>
                                <Form.Control placeholder={'Write your comment'} name={'message'} value={comment} onChange={handleComment} className={`bg-white bg-opacity-25 text-white mb-4 ${validComment && 'is-valid'}`} />
                            </Form.FloatingLabel>
                            <Button type={'submit'} variant={'success'} className={'py2 px-5 rounded-pill'}>Send</Button>
                        </Form>
                    </Col>
                    <Col sm={12} lg={6} className={'bg-transparent px-5 text-white text-start d-flex flex-column'}>
                        <div className={''}>
                            <div className={'text-center float-start'}>
                                <svg width="125" height="129" viewBox="0 0 125 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.8386 126.586H23.0651V125.812C22.8741 126.089 22.6258 126.299 22.3011 126.452C21.9764 126.605 21.6326 126.681 21.2601 126.681C20.7444 126.681 20.3147 126.548 19.99 126.29C19.6653 126.023 19.5029 125.679 19.5029 125.249C19.5029 124.771 19.6844 124.418 20.0473 124.17C20.4102 123.922 20.9545 123.807 21.6612 123.807H23.046C23.0364 123.368 22.9218 123.024 22.7022 122.785C22.4825 122.546 22.1578 122.422 21.7376 122.422C21.4893 122.422 21.2506 122.47 21.0023 122.575C20.7635 122.68 20.5248 122.842 20.2956 123.052L19.8754 122.527C20.1523 122.269 20.4484 122.069 20.7731 121.935C21.0978 121.801 21.4416 121.735 21.814 121.735C22.4443 121.735 22.9314 121.926 23.2943 122.298C23.6476 122.67 23.8291 123.186 23.8291 123.845V126.586H23.8386ZM23.0555 124.428H21.6803C21.2028 124.428 20.859 124.495 20.6298 124.619C20.4006 124.752 20.286 124.953 20.286 125.23C20.286 125.459 20.3815 125.66 20.5821 125.812C20.7826 125.965 21.0309 126.042 21.3461 126.042C21.814 126.042 22.2056 125.889 22.5303 125.593C22.855 125.297 23.0364 124.905 23.0555 124.428Z" fill="#FDFEFF"/>
                                    <path d="M28.652 119.767H29.4542V126.586H28.6616V125.822C28.4515 126.099 28.1936 126.309 27.888 126.452C27.5824 126.605 27.2673 126.672 26.9139 126.672C26.255 126.672 25.7106 126.443 25.2618 125.984C24.8129 125.526 24.5933 124.943 24.5933 124.237C24.5933 123.52 24.8225 122.928 25.2809 122.46C25.7393 121.992 26.3027 121.754 26.9712 121.754C27.2959 121.754 27.6015 121.821 27.888 121.954C28.1745 122.088 28.4324 122.279 28.6425 122.527V119.767H28.652ZM27.0094 125.994C27.4869 125.994 27.8785 125.822 28.2032 125.478C28.5279 125.134 28.6902 124.714 28.6902 124.218C28.6902 123.711 28.5374 123.291 28.2223 122.957C27.9071 122.623 27.5251 122.46 27.0572 122.46C26.5797 122.46 26.1881 122.623 25.8825 122.947C25.5769 123.272 25.4241 123.702 25.4241 124.227C25.4241 124.752 25.5769 125.182 25.873 125.507C26.1595 125.832 26.5415 125.994 27.0094 125.994Z" fill="#FDFEFF"/>
                                    <path d="M32.0995 126.586L30.0845 121.878H30.9535L32.4815 125.641L34 121.878H34.8213L32.8253 126.586H32.0995Z" fill="#FDFEFF"/>
                                    <path d="M37.4376 126.061C37.7527 126.061 38.0392 125.994 38.2971 125.86C38.5645 125.726 38.8032 125.526 39.0229 125.249L39.5004 125.688C39.233 126.042 38.9178 126.299 38.5645 126.471C38.2111 126.643 37.81 126.729 37.3707 126.729C36.664 126.729 36.0815 126.5 35.6326 126.032C35.1838 125.564 34.9546 124.972 34.9546 124.246C34.9546 123.54 35.1838 122.947 35.6422 122.47C36.1006 121.992 36.6736 121.763 37.3516 121.763C38.0392 121.763 38.6027 121.983 39.0229 122.432C39.4526 122.881 39.6627 123.463 39.6627 124.189C39.6627 124.237 39.6627 124.304 39.6532 124.37C39.6436 124.437 39.6436 124.485 39.6341 124.523H35.7663C35.8141 124.982 35.9955 125.354 36.3107 125.641C36.6258 125.917 36.9983 126.061 37.4376 126.061ZM37.3612 122.441C36.9505 122.441 36.5972 122.575 36.3107 122.842C36.0146 123.11 35.8427 123.463 35.7759 123.912H38.8701C38.8128 123.454 38.6504 123.1 38.3735 122.833C38.1061 122.575 37.7623 122.441 37.3612 122.441Z" fill="#FDFEFF"/>
                                    <path d="M40.4746 121.887H41.2577V122.479C41.401 122.269 41.5824 122.117 41.8116 122.012C42.0408 121.906 42.3082 121.859 42.6234 121.859H43.0531V122.613H42.6138C42.165 122.613 41.8307 122.728 41.6015 122.967C41.3819 123.196 41.2673 123.559 41.2673 124.027V126.596H40.4746V121.887Z" fill="#FDFEFF"/>
                                    <path d="M43.9509 125.335V122.585H43.311V121.887H43.9509V120.445H44.7435V121.887H45.8322V122.585H44.7435V125.268C44.7435 125.478 44.7913 125.631 44.8868 125.726C44.9823 125.832 45.1255 125.879 45.3165 125.879H45.8322V126.586H45.2019C44.7626 126.586 44.4475 126.481 44.2565 126.28C44.0464 126.089 43.9509 125.765 43.9509 125.335Z" fill="#FDFEFF"/>
                                    <path d="M46.6821 120.302H47.5607V121.171H46.6821V120.302ZM46.7203 126.586V121.878H47.5225V126.586H46.7203Z" fill="#FDFEFF"/>
                                    <path d="M52.4311 125.22C52.4311 125.66 52.2402 126.023 51.8677 126.299C51.4953 126.576 50.9987 126.72 50.397 126.72C49.9959 126.72 49.633 126.653 49.2796 126.529C48.9358 126.405 48.6207 126.214 48.3438 125.956L48.7544 125.354C49.0504 125.602 49.337 125.784 49.5948 125.889C49.8527 126.003 50.1296 126.051 50.4256 126.051C50.7981 126.051 51.1037 125.975 51.3329 125.832C51.5621 125.688 51.6767 125.497 51.6767 125.259C51.6767 125.02 51.5812 124.848 51.3806 124.733C51.1896 124.628 50.865 124.571 50.4161 124.561C49.7572 124.533 49.2797 124.418 48.9836 124.198C48.678 123.988 48.5348 123.654 48.5348 123.196C48.5348 122.776 48.7067 122.432 49.06 122.155C49.4038 121.878 49.8527 121.744 50.397 121.744C50.7695 121.744 51.1133 121.801 51.438 121.916C51.7627 122.031 52.0587 122.203 52.3261 122.432L51.9441 123.024C51.6767 122.814 51.4189 122.651 51.1706 122.556C50.9223 122.46 50.6644 122.413 50.3779 122.413C50.0627 122.413 49.7954 122.479 49.5948 122.623C49.3943 122.766 49.2892 122.938 49.2892 123.148C49.2892 123.377 49.3847 123.54 49.5661 123.645C49.7476 123.75 50.0341 123.807 50.4352 123.826C51.1419 123.845 51.6576 123.96 51.9728 124.179C52.2688 124.418 52.4311 124.752 52.4311 125.22Z" fill="#FDFEFF"/>
                                    <path d="M53.272 120.302H54.1506V121.171H53.272V120.302ZM53.3102 126.586V121.878H54.1124V126.586H53.3102Z" fill="#FDFEFF"/>
                                    <path d="M55.2295 121.887H56.0126V122.623C56.2036 122.346 56.4423 122.136 56.7288 121.992C57.0153 121.849 57.34 121.773 57.7029 121.773C58.3237 121.773 58.8107 121.973 59.1641 122.374C59.5174 122.776 59.6893 123.329 59.6893 124.027V126.586H58.8967V124.074C58.8967 123.578 58.7725 123.196 58.5338 122.909C58.2855 122.623 57.9512 122.479 57.5119 122.479C57.0631 122.479 56.7002 122.632 56.4328 122.938C56.1558 123.243 56.0221 123.635 56.0221 124.122V126.586H55.2295V121.887Z" fill="#FDFEFF"/>
                                    <path d="M64.5119 126.032V125.698C64.3018 125.956 64.0439 126.156 63.7383 126.299C63.4327 126.443 63.1176 126.51 62.7642 126.51C62.1053 126.51 61.5514 126.29 61.1121 125.851C60.6728 125.411 60.4531 124.838 60.4531 124.141C60.4531 123.454 60.6823 122.881 61.1312 122.432C61.58 121.983 62.1435 121.754 62.8024 121.754C63.1367 121.754 63.4614 121.821 63.7574 121.964C64.0535 122.107 64.3113 122.308 64.531 122.565V121.868H65.3236V125.994C65.3236 126.777 65.1135 127.36 64.7029 127.751C64.2827 128.143 63.6619 128.334 62.8215 128.334H61.6373V127.656H62.8693C63.4232 127.656 63.8338 127.522 64.1203 127.245C64.3686 126.978 64.5119 126.576 64.5119 126.032ZM62.8788 125.822C63.3468 125.822 63.7383 125.66 64.063 125.335C64.3877 125.01 64.5501 124.619 64.5501 124.151C64.5501 123.673 64.3877 123.272 64.063 122.947C63.7383 122.623 63.3468 122.46 62.8788 122.46C62.3918 122.46 62.0002 122.613 61.7042 122.919C61.4081 123.224 61.2553 123.625 61.2553 124.141C61.2553 124.638 61.4081 125.048 61.7042 125.354C62.0098 125.669 62.4013 125.822 62.8788 125.822Z" fill="#FDFEFF"/>
                                    <path d="M70.3566 126.739C69.6308 126.739 69.0483 126.51 68.5994 126.051C68.1506 125.593 67.9214 124.991 67.9214 124.256C67.9214 123.559 68.1601 122.967 68.6281 122.479C69.1056 122.002 69.6881 121.754 70.3757 121.754C70.8723 121.754 71.3021 121.868 71.6554 122.097C72.0088 122.327 72.2762 122.661 72.4576 123.1L71.7891 123.425C71.6363 123.1 71.4453 122.852 71.2066 122.69C70.9678 122.527 70.6909 122.441 70.3662 122.441C69.9078 122.441 69.5162 122.613 69.2011 122.967C68.8859 123.31 68.7331 123.74 68.7331 124.246C68.7331 124.762 68.8859 125.182 69.2011 125.526C69.5162 125.87 69.9078 126.042 70.3853 126.042C70.6909 126.042 70.9774 125.956 71.2352 125.793C71.4931 125.631 71.7127 125.392 71.8751 125.096L72.4863 125.43C72.2953 125.832 72.0183 126.156 71.6363 126.385C71.2639 126.615 70.8341 126.739 70.3566 126.739Z" fill="#FDFEFF"/>
                                    <path d="M77.968 124.237C77.968 124.943 77.7292 125.535 77.2613 126.013C76.7838 126.49 76.2012 126.729 75.5041 126.729C74.8069 126.729 74.2244 126.49 73.766 126.023C73.2981 125.545 73.0688 124.953 73.0688 124.246C73.0688 123.54 73.3076 122.947 73.7756 122.47C74.253 121.992 74.8356 121.754 75.5328 121.754C76.2299 121.754 76.8029 121.992 77.2708 122.46C77.7292 122.938 77.968 123.53 77.968 124.237ZM73.8711 124.256C73.8711 124.762 74.0239 125.192 74.339 125.535C74.6542 125.879 75.0457 126.051 75.5137 126.051C75.9721 126.051 76.3636 125.879 76.6788 125.535C76.9939 125.192 77.1467 124.762 77.1467 124.246C77.1467 123.75 76.9844 123.32 76.6692 122.976C76.3541 122.632 75.9625 122.451 75.5041 122.451C75.0457 122.451 74.6542 122.623 74.339 122.967C74.0334 123.31 73.8711 123.74 73.8711 124.256Z" fill="#FDFEFF"/>
                                    <path d="M78.7798 121.887H79.5629V122.451C79.7348 122.222 79.9353 122.05 80.1645 121.935C80.3842 121.821 80.642 121.763 80.919 121.763C81.2437 121.763 81.5302 121.83 81.788 121.964C82.0459 122.097 82.2464 122.298 82.3897 122.556C82.5616 122.298 82.7908 122.097 83.0582 121.964C83.3256 121.83 83.6312 121.763 83.9654 121.763C84.5193 121.763 84.9586 121.945 85.2833 122.298C85.608 122.651 85.7799 123.119 85.7799 123.692V126.586H84.9777V123.74C84.9777 123.349 84.8822 123.033 84.6817 122.814C84.4811 122.594 84.2042 122.479 83.8508 122.479C83.4975 122.479 83.2205 122.604 83.0009 122.842C82.7812 123.091 82.6762 123.406 82.6762 123.788V126.586H81.8835V123.74C81.8835 123.349 81.788 123.033 81.5875 122.814C81.3869 122.594 81.11 122.479 80.7566 122.479C80.4033 122.479 80.1263 122.604 79.9067 122.842C79.687 123.091 79.582 123.406 79.582 123.788V126.586H78.7893V121.887H78.7798Z" fill="#FDFEFF"/>
                                    <path d="M87.5466 128.343H86.7539V121.887H87.537V122.651C87.7471 122.374 88.005 122.164 88.3106 122.012C88.6162 121.859 88.9409 121.782 89.2847 121.782C89.9436 121.782 90.4975 122.012 90.9368 122.47C91.3761 122.928 91.5958 123.511 91.5958 124.227C91.5958 124.953 91.3666 125.545 90.9082 126.003C90.4498 126.471 89.8863 126.701 89.2178 126.701C88.9027 126.701 88.5971 126.634 88.301 126.5C88.005 126.366 87.7567 126.175 87.5466 125.927V128.343ZM89.1892 122.479C88.7117 122.479 88.3201 122.651 87.9954 122.995C87.6707 123.339 87.5084 123.759 87.5084 124.265C87.5084 124.762 87.6612 125.182 87.9763 125.516C88.2915 125.851 88.683 126.013 89.151 126.013C89.6285 126.013 90.02 125.851 90.3256 125.526C90.6312 125.201 90.7745 124.781 90.7745 124.246C90.7745 123.721 90.6312 123.291 90.3352 122.967C90.0487 122.642 89.6667 122.479 89.1892 122.479Z" fill="#FDFEFF"/>
                                    <path d="M96.4568 126.586H95.6832V125.812C95.4922 126.089 95.2439 126.299 94.9192 126.452C94.5945 126.605 94.2507 126.681 93.8783 126.681C93.3626 126.681 92.9329 126.548 92.6082 126.29C92.2835 126.023 92.1211 125.679 92.1211 125.249C92.1211 124.771 92.3026 124.418 92.6655 124.17C93.0284 123.922 93.5727 123.807 94.2794 123.807H95.6642C95.6546 123.368 95.54 123.024 95.3203 122.785C95.1007 122.546 94.776 122.422 94.3558 122.422C94.1075 122.422 93.8688 122.47 93.6205 122.575C93.3817 122.68 93.1429 122.842 92.9137 123.052L92.4935 122.527C92.7705 122.269 93.0665 122.069 93.3912 121.935C93.7159 121.801 94.0598 121.735 94.4322 121.735C95.0625 121.735 95.5496 121.926 95.9125 122.298C96.2658 122.67 96.4472 123.186 96.4472 123.845V126.586H96.4568ZM95.6832 124.428H94.308C93.8305 124.428 93.4867 124.495 93.2575 124.619C93.0283 124.752 92.9137 124.953 92.9137 125.23C92.9137 125.459 93.0092 125.66 93.2098 125.812C93.4103 125.965 93.6586 126.042 93.9738 126.042C94.4417 126.042 94.8333 125.889 95.158 125.593C95.4827 125.297 95.6546 124.905 95.6832 124.428Z" fill="#FDFEFF"/>
                                    <path d="M97.4312 121.887H98.2142V122.623C98.4052 122.346 98.644 122.136 98.9305 121.992C99.217 121.849 99.5417 121.773 99.9046 121.773C100.525 121.773 101.012 121.973 101.366 122.374C101.719 122.776 101.891 123.329 101.891 124.027V126.586H101.098V124.074C101.098 123.578 100.974 123.196 100.735 122.909C100.487 122.623 100.153 122.479 99.7136 122.479C99.2647 122.479 98.9019 122.632 98.6345 122.938C98.3575 123.243 98.2238 123.635 98.2238 124.122V126.586H97.4312V121.887Z" fill="#FDFEFF"/>
                                    <path d="M104.269 126.548L102.282 121.878H103.151L104.67 125.621L106.188 121.878H107.019L104.278 128.334H103.457L104.269 126.548Z" fill="#FDFEFF"/>
                                    <path d="M48.7737 73.0582H35.27V45.6211C35.27 39.7383 40.0546 34.9537 45.9374 34.9537H68.3512C70.691 34.9537 72.4291 34.2948 73.661 32.9482C74.893 31.5921 75.5233 29.6535 75.5233 27.1705C75.5233 24.7734 74.9025 22.9016 73.6801 21.6124C72.4482 20.3231 70.7101 19.6929 68.3608 19.6929H54.0262C43.6836 19.6929 35.2796 11.2793 35.2796 0.9462V0.0867004H69.5832C79.0377 0.0867004 86.4771 2.3978 91.701 6.95315C96.9535 11.5467 99.6179 18.1075 99.6179 26.4542C99.6179 35.2689 96.963 42.2308 91.7201 47.1586C86.4962 52.0673 79.0472 54.5599 69.5832 54.5599H60.5871V61.2544C60.5775 67.7675 55.2773 73.0582 48.7737 73.0582ZM36.989 71.3392H48.7642C54.3223 71.3392 58.849 66.8125 58.849 61.2544V52.8409H69.5641C74.0908 52.8409 78.1591 52.2488 81.6448 51.0932C85.1019 49.9472 88.0911 48.1996 90.5263 45.9076C92.9616 43.6156 94.8143 40.8365 96.0271 37.6277C97.2591 34.3998 97.8798 30.6371 97.8798 26.4638C97.8798 22.5292 97.2591 18.9957 96.0367 15.9779C94.8238 12.9792 92.9807 10.3816 90.555 8.2615C85.6558 3.9831 78.5984 1.81525 69.5736 1.81525H37.0177C37.4665 10.8113 44.9251 17.9834 54.0262 17.9834H68.3608C71.1685 17.9834 73.3745 18.8047 74.9312 20.4377C76.4687 22.0517 77.2518 24.3246 77.2518 27.18C77.2518 30.0928 76.4783 32.4325 74.9407 34.1133C73.3841 35.8132 71.1685 36.6823 68.3608 36.6823H45.9374C41 36.6823 36.989 40.6933 36.989 45.6306V71.3392Z" fill="white"/>
                                    <path d="M48.7737 73.0583H35.27V45.6211C35.27 39.7383 40.0546 34.9538 45.9374 34.9538H68.3512C70.691 34.9538 72.4291 34.2948 73.661 32.9483C74.893 31.5922 75.5233 29.6535 75.5233 27.1705C75.5233 24.7735 74.9025 22.9017 73.6801 21.6124C72.5723 20.4473 71.0443 19.8361 69.0197 19.7215L69.0961 18.0025C75.3514 18.184 81.196 20.7529 85.5699 25.2319C89.9438 29.7299 92.3599 35.6604 92.3599 41.9348C92.3599 43.5583 92.188 45.1914 91.8633 46.8053L91.806 47.0727L91.6055 47.2637C86.3912 52.1056 78.9804 54.5599 69.5927 54.5599H60.5966V61.2545C60.5775 67.7676 55.2773 73.0583 48.7737 73.0583ZM36.989 71.3393H48.7642C54.3223 71.3393 58.849 66.8126 58.849 61.2545V52.8409H69.5641C74.0526 52.8409 78.0922 52.2584 81.5589 51.1219C84.9014 50.0237 87.8046 48.362 90.2016 46.1941C90.4786 44.7903 90.6123 43.3578 90.6123 41.9444C90.6123 36.1189 88.3776 30.6085 84.3093 26.4352C81.7403 23.7994 78.627 21.8798 75.2177 20.7816C76.5451 22.3669 77.2232 24.5156 77.2232 27.1705C77.2232 30.0833 76.4496 32.423 74.9121 34.1038C73.3554 35.8037 71.1398 36.6728 68.3321 36.6728H45.9374C41 36.6728 36.989 40.6838 36.989 45.6211V71.3393Z" fill="white"/>
                                    <path d="M49.003 73.0296H35.3179L35.5566 71.9791C36.7504 66.6216 39.7682 61.7606 44.0561 58.2844C48.4014 54.7605 53.8831 52.8123 59.4985 52.8123C59.6035 52.8123 59.699 52.8123 59.8041 52.8218C59.8614 52.8218 59.9187 52.8218 59.976 52.8314L60.8164 52.8505V61.2354C60.8068 67.7294 55.5066 73.0296 49.003 73.0296ZM37.4857 71.3106H49.003C54.5611 71.3106 59.0878 66.7839 59.0878 61.2258V54.5408C54.0168 54.6363 49.0699 56.4317 45.1353 59.6214C41.4299 62.6297 38.7368 66.7457 37.4857 71.3106Z" fill="white"/>
                                    <path d="M0.947266 112.175V87.0203H12.4837C15.3582 87.0203 17.6311 87.7461 19.3119 89.1977C20.9927 90.6493 21.8236 92.6071 21.8236 95.071C21.8236 97.5349 20.9163 99.5308 19.1114 101.04C17.3064 102.549 14.9285 103.303 11.9775 103.303H5.54082V112.175H0.947266ZM17.1536 95.1474C17.1536 93.8772 16.7334 92.884 15.893 92.1678C15.0526 91.4515 13.878 91.0886 12.3691 91.0886H5.54082V99.2825H12.1494C13.687 99.2825 14.8998 98.9101 15.7975 98.1652C16.7048 97.4203 17.1536 96.4175 17.1536 95.1474Z" fill="white"/>
                                    <path d="M24.3066 87.0203H28.6232V112.175H24.3066V87.0203Z" fill="white"/>
                                    <path d="M49.2414 112.175H45.0394V110.055C44.3422 110.895 43.5114 111.526 42.5373 111.955C41.5632 112.385 40.4745 112.605 39.2521 112.605C36.9505 112.605 35.1551 111.879 33.8659 110.427C32.5766 108.976 31.9272 106.97 31.9272 104.411V94.4216H36.2438V104.019C36.2438 105.528 36.6067 106.712 37.3421 107.581C38.0774 108.441 39.0993 108.871 40.4172 108.871C41.7828 108.871 42.8811 108.403 43.7024 107.467C44.5332 106.531 44.9439 105.309 44.9439 103.8V94.4216H49.2605V112.175H49.2414Z" fill="white"/>
                                    <path d="M52.479 94.4311H56.6524V96.1883C57.2731 95.4721 57.9894 94.9277 58.8107 94.5553C59.6224 94.1828 60.5201 94.0014 61.5038 94.0014C62.6498 94.0014 63.6812 94.221 64.598 94.6508C65.5052 95.0805 66.2501 95.7013 66.8231 96.5226C67.5203 95.7108 68.3511 95.0805 69.3252 94.6508C70.2993 94.221 71.3785 94.0014 72.5818 94.0014C74.7401 94.0014 76.44 94.6508 77.6815 95.9591C78.923 97.2675 79.5533 99.0151 79.5533 101.221V112.185H75.2367V101.622C75.2367 100.4 74.9406 99.4449 74.339 98.7668C73.7373 98.0888 72.8969 97.745 71.8273 97.745C70.7291 97.745 69.8409 98.127 69.1724 98.8814C68.5039 99.6359 68.1697 100.629 68.1697 101.842V112.194H63.8531V101.632C63.8531 100.409 63.557 99.4544 62.9554 98.7764C62.3537 98.0983 61.5133 97.7545 60.4437 97.7545C59.3455 97.7545 58.4573 98.1365 57.7984 98.891C57.1394 99.6454 56.8147 100.639 56.8147 101.851V112.204H52.4981V94.4311H52.479Z" fill="white"/>
                                    <path d="M86.7637 112.175H82.5903V87.0203H86.9069V96.3602C87.6996 95.5962 88.5782 95.0137 89.5523 94.6126C90.5264 94.221 91.5673 94.0205 92.6942 94.0205C95.1868 94.0205 97.2591 94.88 98.9304 96.6085C100.592 98.3371 101.432 100.534 101.432 103.217C101.432 105.948 100.583 108.193 98.8826 109.959C97.1827 111.717 95.0722 112.605 92.5605 112.605C91.4336 112.605 90.3736 112.395 89.3804 111.974C88.3872 111.554 87.5181 110.943 86.7732 110.122V112.175H86.7637ZM92.0066 97.6972C90.5168 97.6972 89.2753 98.232 88.2726 99.3112C87.2698 100.39 86.7637 101.727 86.7637 103.332C86.7637 104.936 87.2603 106.264 88.2535 107.324C89.2467 108.374 90.4786 108.909 91.9398 108.909C93.4773 108.909 94.7093 108.393 95.6547 107.362C96.6002 106.33 97.0777 104.974 97.0777 103.303C97.0777 101.622 96.6002 100.276 95.6547 99.2443C94.7093 98.2129 93.4964 97.6972 92.0066 97.6972Z" fill="white"/>
                                    <path d="M104.078 87.0203H108.681V91.3656H104.078V87.0203ZM104.221 112.175V94.4216H108.537V112.175H104.221Z" fill="white"/>
                                    <path d="M112.271 87.0203H116.588V112.175H112.271V87.0203Z" fill="white"/>
                                    <path d="M120.322 87.0203H124.639V112.175H120.322V87.0203Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div className={'mt-5 float-start'}>
                        <Row className={'mb-3'}>
                            <Col sm={4}>
                                <p className={'fw-bold'} style={{letterSpacing: '0.3px'}}>Address</p>
                            </Col>
                            <Col sm={8}>
                                <p>30 N Gould St Ste R <br />
                                    Sheredan, WY 82801 USA</p>
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col sm={4}>
                                <p className={'fw-bold'} style={{letterSpacing: '0.3px'}}>Phone</p>
                            </Col>
                            <Col sm={8}>
                                <p>619.753.1955</p>
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col sm={4}>
                                <p className={'fw-bold'} style={{letterSpacing: '0.3px'}}>Email</p>
                            </Col>
                            <Col sm={8}>
                                <p>support@plumbill.com</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <p className={'fw-bold'} style={{letterSpacing: '0.3px'}}>Follow us</p>
                            </Col>
                            <Col sm={8} className={'d-block'}>
                                <div className={'d-flex'}>
                                    <Button className={'rounded-circle form-social d-flex justify-content-center align-items-center me-3 p-0'} style={{height: '30px', width: '30px'}}>
                                        <svg width="12" height="12" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.3934 0H3.81518C1.70844 0 0 1.69998 0 3.7963V8.35185C0 10.4482 1.70844 12.1482 3.81518 12.1482H8.3934C10.5001 12.1482 12.2086 10.4482 12.2086 8.35185V3.7963C12.2086 1.69998 10.5001 0 8.3934 0ZM11.064 8.35185C11.064 9.81723 9.86606 11.0093 8.3934 11.0093H3.81518C2.34252 11.0093 1.14455 9.81723 1.14455 8.35185V3.7963C1.14455 2.33093 2.34252 1.13889 3.81518 1.13889H8.3934C9.86606 1.13889 11.064 2.33093 11.064 3.7963V8.35185Z" fill="white"/>
                                            <path d="M6.11074 3.04395C4.42519 3.04395 3.05859 4.40378 3.05859 6.08098C3.05859 7.75819 4.42519 9.11802 6.11074 9.11802C7.79629 9.11802 9.16289 7.75819 9.16289 6.08098C9.16289 4.40378 7.79629 3.04395 6.11074 3.04395ZM6.11074 7.97913C5.05928 7.97913 4.20315 7.12724 4.20315 6.08098C4.20315 5.03396 5.05928 4.18283 6.11074 4.18283C7.1622 4.18283 8.01833 5.03396 8.01833 6.08098C8.01833 7.12724 7.1622 7.97913 6.11074 7.97913Z" fill="white"/>
                                            <path d="M9.37697 3.22354C9.60216 3.22354 9.7847 3.04189 9.7847 2.81782C9.7847 2.59375 9.60216 2.41211 9.37697 2.41211C9.15179 2.41211 8.96924 2.59375 8.96924 2.81782C8.96924 3.04189 9.15179 3.22354 9.37697 3.22354Z" fill="white"/>
                                        </svg>
                                    </Button>
                                    <Button className={'rounded-circle form-social me-3 d-flex justify-content-center align-items-center p-0'} style={{height: '30px', width: '30px'}}>
                                        <svg width="11px" height="11px" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3162 11.2574V11.257H11.3191V7.12363C11.3191 5.10157 10.8816 3.54395 8.50591 3.54395C7.36385 3.54395 6.59744 4.16756 6.28456 4.75877H6.25152V3.73272H3.99902V11.257H6.34449V7.53123C6.34449 6.55026 6.53137 5.60169 7.75224 5.60169C8.95518 5.60169 8.97311 6.72119 8.97311 7.59416V11.2574H11.3162Z" fill="white"/>
                                            <path d="M0.179688 3.74609H2.52799V11.2703H0.179688V3.74609Z" fill="white"/>
                                            <path d="M1.36009 0C0.609255 0 0 0.60624 0 1.35336C0 2.10048 0.609255 2.71939 1.36009 2.71939C2.11092 2.71939 2.72017 2.10048 2.72017 1.35336C2.7197 0.60624 2.11045 0 1.36009 0V0Z" fill="white"/>
                                        </svg>
                                    </Button>
                                    <Button className={'rounded-circle form-social me-3 d-flex justify-content-center align-items-center p-0'} style={{height: '30px', width: '30px'}}>
                                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.73547 0.00268021L5.05672 0C3.1707 0 1.95187 1.24429 1.95187 3.17016V4.63181H0.263967C0.118112 4.63181 0 4.74947 0 4.89461V7.01238C0 7.15751 0.118247 7.27504 0.263967 7.27504H1.95187V12.6189C1.95187 12.764 2.06999 12.8815 2.21584 12.8815H4.41808C4.56394 12.8815 4.68205 12.7639 4.68205 12.6189V7.27504H6.6556C6.80146 7.27504 6.91957 7.15751 6.91957 7.01238L6.92038 4.89461C6.92038 4.82492 6.8925 4.75818 6.84308 4.70887C6.79365 4.65955 6.72631 4.63181 6.65628 4.63181H4.68205V3.39275C4.68205 2.79721 4.82467 2.49488 5.60432 2.49488L6.7352 2.49448C6.88092 2.49448 6.99903 2.37681 6.99903 2.23181V0.265341C6.99903 0.120476 6.88105 0.00294824 6.73547 0.00268021Z" fill="white"/>
                                        </svg>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        </div>
                    </Col>
                    <hr className={'mt-5 text-white-50'}/>
                </Row>
            </Container>
            <p className={'mt-4 text-white-50 text-center small'}>© 2023 Plumbill All Rights Reserved.</p>
        </div>
    );
};

export default FormComponent;