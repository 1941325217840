import React from "react";

import "./Styles.css";
import {Button, Card} from "react-bootstrap";
import Product from "./Product";

export default function Slide({data, scrollToContact}) {
    return (
        <div className="slide d-flex justify-content-center">
            {data.map(product => (
                <Product product={product} scrollToContact={scrollToContact} />
            ))}
        </div>
    );
}