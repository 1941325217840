import React, { useContext } from "react";
import Slide from "./Slide";
import { SliderContext } from "./Slider";

import "./Styles.css";

export default function SlidesList({scrollToContact}) {
    const { slideNumber, items } = useContext(SliderContext);

    return (
        <div
            className="slide-list pt-5 pb-4"
            style={{ transform: `translateX(-${slideNumber * 100}%)` }}
        >
            {items.map((slide, index) => (
                <Slide key={index} data={slide} scrollToContact={scrollToContact} />
            ))}
        </div>
    );
}