import React from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";

const NavBar = ({scroll, aboutRef, contactRef, productsRef}) => {
    return (
        <Navbar expand={'lg'} className={'mt-lg-3'} variant={'dark'}>
            <Container>
            <Navbar.Brand className={'d-flex'}>
                <div>
                    {window.innerWidth > 992 ?
                        <svg width="182" height="65" viewBox="0 0 182 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_11_198)">
                                <path d="M90.3496 53.4992H89.6492V52.7965C89.4772 53.0459 89.2458 53.2388 88.9535 53.3784C88.6611 53.5181 88.3469 53.5871 88.0092 53.5871C87.5371 53.5871 87.1525 53.4679 86.857 53.2279C86.5615 52.9879 86.4146 52.6726 86.4146 52.2804C86.4146 51.8475 86.5803 51.5197 86.9101 51.2985C87.2416 51.0758 87.7309 50.9644 88.3813 50.9644H89.6398C89.6336 50.5613 89.5288 50.2507 89.3256 50.0311C89.1223 49.8115 88.8316 49.7017 88.4532 49.7017C88.2281 49.7017 88.0076 49.7488 87.7888 49.8445C87.5699 49.9386 87.3541 50.0845 87.1415 50.2805L86.7601 49.8005C87.0086 49.5637 87.2791 49.3849 87.5746 49.2625C87.8701 49.1417 88.1859 49.0806 88.522 49.0806C89.0942 49.0806 89.5429 49.2515 89.8649 49.5919C90.187 49.9323 90.348 50.4029 90.348 51.0005V53.4977L90.3496 53.4992ZM89.6414 51.526H88.3922C87.9607 51.526 87.6418 51.5856 87.4354 51.7032C87.2291 51.8224 87.1259 52.0091 87.1259 52.2632C87.1259 52.4765 87.2166 52.6538 87.3964 52.7965C87.5762 52.9393 87.8091 53.0098 88.0921 53.0098C88.5173 53.0098 88.8769 52.8718 89.1692 52.5957C89.4616 52.3197 89.6195 51.9636 89.6429 51.5244L89.6414 51.526Z" fill="#9A9A9A"/>
                                <path d="M94.7255 47.2767H95.4525V53.4992H94.7349V52.8059C94.5395 53.0553 94.305 53.2467 94.0298 53.3832C93.7547 53.5196 93.4607 53.5871 93.1481 53.5871C92.5509 53.5871 92.0506 53.3785 91.6457 52.9596C91.2407 52.5424 91.0391 52.0107 91.0391 51.3644C91.0391 50.7182 91.247 50.1723 91.6644 49.7425C92.0803 49.3127 92.5931 49.0978 93.2012 49.0978C93.4967 49.0978 93.775 49.159 94.0392 49.2798C94.3018 49.4006 94.5317 49.5747 94.7255 49.799V47.2751V47.2767ZM93.2278 52.9565C93.6593 52.9565 94.0204 52.8012 94.3128 52.4891C94.6051 52.1769 94.7521 51.7942 94.7521 51.3377C94.7521 50.8813 94.6098 50.4939 94.3269 50.1911C94.0439 49.8884 93.689 49.7378 93.2638 49.7378C92.8385 49.7378 92.4696 49.8868 92.1913 50.1817C91.913 50.4782 91.7754 50.8656 91.7754 51.3456C91.7754 51.8256 91.9114 52.2146 92.1835 52.5095C92.4555 52.8059 92.8041 52.9534 93.2294 52.9534L93.2278 52.9565Z" fill="#9A9A9A"/>
                                <path d="M97.8631 53.4992L96.0293 49.2061H96.8172L98.2087 52.6365L99.5907 49.2061H100.335L98.5182 53.4992H97.8631Z" fill="#9A9A9A"/>
                                <path d="M102.718 53.0192C103.001 53.0192 103.263 52.9581 103.502 52.8373C103.742 52.7165 103.962 52.5298 104.162 52.282L104.597 52.682C104.354 53.002 104.071 53.2404 103.746 53.3973C103.421 53.5541 103.058 53.6325 102.657 53.6325C102.013 53.6325 101.486 53.4208 101.074 52.9973C100.663 52.5738 100.458 52.0295 100.458 51.366C100.458 50.7024 100.668 50.1801 101.087 49.7488C101.506 49.3158 102.023 49.0994 102.638 49.0994C103.252 49.0994 103.771 49.3033 104.162 49.7127C104.551 50.1221 104.747 50.6554 104.747 51.3126C104.747 51.3597 104.744 51.4146 104.737 51.4773C104.731 51.5401 104.725 51.5856 104.72 51.6154H101.203C101.25 52.0357 101.414 52.3745 101.695 52.6334C101.975 52.8906 102.317 53.0208 102.719 53.0208L102.718 53.0192ZM102.647 49.7127C102.275 49.7127 101.956 49.8335 101.69 50.0766C101.425 50.3197 101.262 50.646 101.203 51.0538H104.02C103.967 50.6397 103.817 50.3119 103.568 50.0719C103.319 49.8319 103.013 49.7127 102.647 49.7127Z" fill="#9A9A9A"/>
                                <path d="M105.472 49.2061H106.18V49.7488C106.31 49.559 106.479 49.4163 106.685 49.3222C106.892 49.2281 107.137 49.1794 107.42 49.1794H107.809V49.8633H107.411C107.003 49.8633 106.698 49.97 106.493 50.1833C106.29 50.3967 106.187 50.7198 106.187 51.1527V53.4993H105.469V49.2061H105.472Z" fill="#9A9A9A"/>
                                <path d="M108.635 52.3527V49.8461H108.05V49.2062H108.635V47.8901H109.353V49.2062H110.345V49.8461H109.353V52.29C109.353 52.4798 109.395 52.6209 109.481 52.7119C109.567 52.8045 109.698 52.85 109.875 52.85H110.344V53.4993H109.768C109.373 53.4993 109.085 53.4068 108.904 53.2233C108.724 53.0398 108.633 52.7496 108.633 52.3527H108.635Z" fill="#9A9A9A"/>
                                <path d="M111.116 47.7661H111.913V48.5567H111.116V47.7661ZM111.152 53.4992V49.2061H111.879V53.4992H111.152Z" fill="#9A9A9A"/>
                                <path d="M116.344 52.2461C116.344 52.6492 116.172 52.9786 115.83 53.2327C115.487 53.4868 115.042 53.6154 114.491 53.6154C114.132 53.6154 113.793 53.5574 113.477 53.4413C113.161 53.3252 112.876 53.1527 112.622 52.9205L112.994 52.37C113.266 52.5958 113.519 52.7574 113.755 52.8594C113.991 52.9598 114.243 53.0099 114.509 53.0099C114.851 53.0099 115.128 52.9441 115.337 52.8107C115.547 52.6774 115.651 52.5049 115.651 52.2915C115.651 52.0782 115.562 51.9135 115.386 51.8163C115.209 51.719 114.917 51.6673 114.509 51.661C113.911 51.6374 113.477 51.5276 113.202 51.3316C112.926 51.1355 112.789 50.8312 112.789 50.4155C112.789 50.0359 112.947 49.7207 113.263 49.4681C113.578 49.2156 113.985 49.0901 114.48 49.0901C114.817 49.0901 115.132 49.1434 115.428 49.2501C115.723 49.3567 115.992 49.5136 116.235 49.7207L115.889 50.2634C115.647 50.0673 115.414 49.9261 115.189 49.8367C114.964 49.7473 114.724 49.7034 114.471 49.7034C114.182 49.7034 113.944 49.7693 113.758 49.8995C113.572 50.0297 113.48 50.1897 113.48 50.3795C113.48 50.5865 113.563 50.7387 113.728 50.8328C113.894 50.9269 114.157 50.9834 114.516 51.0022C115.161 51.0194 115.625 51.1261 115.913 51.3222C116.199 51.5182 116.342 51.8257 116.342 52.2461H116.344Z" fill="#9A9A9A"/>
                                <path d="M117.115 47.7661H117.912V48.5567H117.115V47.7661ZM117.151 53.4992V49.2061H117.878V53.4992H117.151Z" fill="#9A9A9A"/>
                                <path d="M118.896 49.206H119.604V49.8727C119.776 49.6233 119.991 49.4335 120.256 49.2986C120.518 49.1652 120.812 49.0994 121.137 49.0994C121.705 49.0994 122.147 49.2829 122.466 49.6499C122.785 50.017 122.945 50.5205 122.945 51.1605V53.4976H122.227V51.2044C122.227 50.7542 122.116 50.3981 121.894 50.1378C121.672 49.8774 121.364 49.7472 120.969 49.7472C120.573 49.7472 120.234 49.8868 119.985 50.1644C119.737 50.4436 119.613 50.8044 119.613 51.2483V53.4976H118.896V49.2045V49.206Z" fill="#9A9A9A"/>
                                <path d="M127.33 52.9927V52.6899C127.135 52.9268 126.9 53.1103 126.625 53.2405C126.35 53.3707 126.056 53.4366 125.743 53.4366C125.141 53.4366 124.638 53.2358 124.238 52.8327C123.836 52.4295 123.636 51.9119 123.636 51.2766C123.636 50.6414 123.841 50.1285 124.252 49.7159C124.663 49.3034 125.168 49.0979 125.771 49.0979C126.078 49.0979 126.369 49.1638 126.639 49.294C126.911 49.4242 127.144 49.6077 127.339 49.8445V49.2046H128.057V52.9644C128.057 53.675 127.868 54.2083 127.489 54.5644C127.111 54.9204 126.542 55.0977 125.779 55.0977H124.699V54.475H125.815C126.317 54.475 126.695 54.351 126.949 54.1016C127.202 53.8522 127.33 53.4821 127.33 52.9911V52.9927ZM125.851 52.7966C126.276 52.7966 126.636 52.6491 126.928 52.3558C127.221 52.0625 127.366 51.7017 127.366 51.2751C127.366 50.8484 127.221 50.4704 126.928 50.1771C126.636 49.8838 126.276 49.7363 125.851 49.7363C125.426 49.7363 125.051 49.8759 124.779 50.1535C124.507 50.4328 124.371 50.8029 124.371 51.2641C124.371 51.7253 124.508 52.0892 124.783 52.3715C125.058 52.6523 125.413 52.7935 125.851 52.7935V52.7966Z" fill="#9A9A9A"/>
                                <path d="M132.645 53.6327C131.99 53.6327 131.457 53.4241 131.046 53.0052C130.635 52.588 130.43 52.0406 130.43 51.3645C130.43 50.7245 130.646 50.185 131.077 49.7473C131.509 49.3081 132.037 49.0901 132.663 49.0901C133.118 49.0901 133.505 49.1952 133.827 49.4054C134.149 49.6156 134.393 49.9199 134.559 50.3167L133.948 50.61C133.812 50.3136 133.637 50.0908 133.421 49.9434C133.205 49.7959 132.95 49.7207 132.655 49.7207C132.236 49.7207 131.883 49.8791 131.596 50.1959C131.31 50.5128 131.167 50.9034 131.167 51.3645C131.167 51.8257 131.31 52.2225 131.596 52.5331C131.883 52.8437 132.242 53.0005 132.674 53.0005C132.952 53.0005 133.21 52.9252 133.449 52.7747C133.688 52.6241 133.882 52.4123 134.029 52.1394L134.587 52.4421C134.415 52.8092 134.159 53.0994 133.816 53.3127C133.474 53.526 133.083 53.6327 132.647 53.6327H132.645Z" fill="#9A9A9A"/>
                                <path d="M139.565 51.3567C139.565 52.0029 139.349 52.5441 138.918 52.9786C138.486 53.4146 137.955 53.6327 137.323 53.6327C136.691 53.6327 136.165 53.4162 135.741 52.9833C135.319 52.5504 135.108 52.0108 135.108 51.3661C135.108 50.7214 135.323 50.1787 135.755 49.7442C136.186 49.3081 136.718 49.0901 137.35 49.0901C137.981 49.0901 138.508 49.3066 138.932 49.7395C139.354 50.1724 139.565 50.712 139.565 51.3567ZM135.844 51.3661C135.844 51.8288 135.988 52.2163 136.274 52.53C136.56 52.8437 136.916 53.0005 137.342 53.0005C137.767 53.0005 138.114 52.8437 138.4 52.53C138.686 52.2163 138.83 51.8241 138.83 51.3567C138.83 50.8892 138.685 50.5144 138.395 50.1959C138.106 49.8791 137.751 49.7207 137.332 49.7207C136.913 49.7207 136.56 49.8775 136.274 50.1912C135.988 50.5049 135.844 50.8971 135.844 51.3645V51.3661Z" fill="#9A9A9A"/>
                                <path d="M140.309 49.206H141.017V49.7221C141.177 49.515 141.358 49.3598 141.563 49.2547C141.766 49.1511 141.996 49.0994 142.249 49.0994C142.545 49.0994 142.808 49.1621 143.037 49.286C143.267 49.4099 143.45 49.5919 143.586 49.8288C143.746 49.5919 143.947 49.4115 144.193 49.286C144.438 49.1621 144.713 49.0994 145.021 49.0994C145.523 49.0994 145.923 49.2625 146.222 49.5888C146.521 49.915 146.669 50.3385 146.669 50.8593V53.4992H145.942V50.9032C145.942 50.5425 145.851 50.2585 145.672 50.0546C145.492 49.8507 145.239 49.7472 144.913 49.7472C144.588 49.7472 144.337 49.8586 144.138 50.0813C143.939 50.304 143.841 50.5926 143.841 50.9487V53.4992H143.123V50.9032C143.123 50.5425 143.033 50.2585 142.853 50.0546C142.673 49.8507 142.42 49.7472 142.096 49.7472C141.773 49.7472 141.519 49.8586 141.321 50.0813C141.122 50.304 141.024 50.5926 141.024 50.9487V53.4992H140.306V49.206H140.309Z" fill="#9A9A9A"/>
                                <path d="M148.273 55.0993H147.555V49.2061H148.264V49.8994C148.453 49.65 148.686 49.4571 148.964 49.3175C149.242 49.1779 149.536 49.1089 149.85 49.1089C150.452 49.1089 150.954 49.3175 151.356 49.7363C151.758 50.1536 151.958 50.6884 151.958 51.341C151.958 51.9935 151.75 52.5394 151.333 52.9629C150.917 53.3864 150.404 53.5981 149.796 53.5981C149.507 53.5981 149.227 53.537 148.958 53.4162C148.689 53.2954 148.461 53.1213 148.271 52.897V55.1008L148.273 55.0993ZM149.771 49.7473C149.339 49.7473 148.978 49.9026 148.686 50.2147C148.393 50.5269 148.248 50.9127 148.248 51.3755C148.248 51.8382 148.392 52.2115 148.678 52.5127C148.964 52.8154 149.32 52.966 149.746 52.966C150.171 52.966 150.538 52.8186 150.814 52.5252C151.089 52.2319 151.226 51.8429 151.226 51.3567C151.226 50.8704 151.092 50.4892 150.823 50.1928C150.554 49.8963 150.204 49.7489 149.772 49.7489L149.771 49.7473Z" fill="#9A9A9A"/>
                                <path d="M156.381 53.4992H155.68V52.7965C155.508 53.0459 155.277 53.2388 154.985 53.3784C154.692 53.5181 154.378 53.5871 154.04 53.5871C153.568 53.5871 153.184 53.4679 152.888 53.2279C152.593 52.9879 152.446 52.6726 152.446 52.2804C152.446 51.8475 152.612 51.5197 152.941 51.2985C153.273 51.0758 153.762 50.9644 154.413 50.9644H155.671C155.665 50.5613 155.56 50.2507 155.357 50.0311C155.154 49.8115 154.863 49.7017 154.484 49.7017C154.259 49.7017 154.039 49.7488 153.82 49.8445C153.601 49.9386 153.385 50.0845 153.173 50.2805L152.791 49.8005C153.04 49.5637 153.31 49.3849 153.606 49.2625C153.901 49.1417 154.217 49.0806 154.553 49.0806C155.125 49.0806 155.574 49.2515 155.896 49.5919C156.218 49.9323 156.379 50.4029 156.379 51.0005V53.4977L156.381 53.4992ZM155.673 51.526H154.423C153.992 51.526 153.673 51.5856 153.467 51.7032C153.26 51.8224 153.157 52.0091 153.157 52.2632C153.157 52.4765 153.248 52.6538 153.428 52.7965C153.607 52.9393 153.84 53.0098 154.123 53.0098C154.549 53.0098 154.908 52.8718 155.2 52.5957C155.493 52.3197 155.651 51.9636 155.674 51.5244L155.673 51.526Z" fill="#9A9A9A"/>
                                <path d="M157.266 49.206H157.974V49.8727C158.146 49.6233 158.362 49.4335 158.626 49.2986C158.888 49.1652 159.182 49.0994 159.508 49.0994C160.075 49.0994 160.517 49.2829 160.836 49.6499C161.155 50.017 161.315 50.5205 161.315 51.1605V53.4976H160.597V51.2044C160.597 50.7542 160.486 50.3981 160.264 50.1378C160.042 49.8774 159.734 49.7472 159.339 49.7472C158.943 49.7472 158.604 49.8868 158.355 50.1644C158.107 50.4436 157.983 50.8044 157.983 51.2483V53.4976H157.266V49.2045V49.206Z" fill="#9A9A9A"/>
                                <path d="M163.477 53.4633L161.67 49.2046H162.458L163.84 52.6178L165.222 49.2046H165.975L163.486 55.0977H162.742L163.477 53.4617V53.4633Z" fill="#9A9A9A"/>
                                <path d="M49.9986 6.07351C45.3773 2.02502 38.8455 0 30.4063 0H0C0 9.01301 7.28225 16.3194 16.2655 16.3194H29.2963C31.6367 16.3194 33.4393 16.9845 34.7025 18.3131C35.9641 19.6432 36.5973 21.5114 36.5973 23.9223C36.5973 26.3332 35.9641 28.3268 34.7025 29.7181C33.4393 31.1095 31.6367 31.8043 29.2963 31.8043H8.91755C3.99289 31.8043 0 35.8105 0 40.7515V65H11.4909C16.9862 65 21.4403 60.5311 21.4403 55.0176V48.1237H30.4047C38.8454 48.1237 45.3773 45.945 49.9971 41.5875C54.6184 37.2285 56.9291 31.1251 56.9291 23.2745C56.9291 15.8567 54.6184 10.1236 49.9971 6.07351H49.9986Z" fill="url(#paint0_linear_11_198)"/>
                                <path d="M49.9986 6.07351C45.3773 2.02502 38.8455 0 30.4063 0H0C0 9.01301 7.28225 16.3194 16.2655 16.3194H29.2963C31.6367 16.3194 33.4393 16.9845 34.7025 18.3131C35.9641 19.6432 36.5973 21.5114 36.5973 23.9223C36.5973 26.3332 35.9641 28.3268 34.7025 29.7181C33.4393 31.1095 31.6367 31.8043 29.2963 31.8043H8.91755C3.99289 31.8043 0 35.8105 0 40.7515V65H11.4909C16.9862 65 21.4403 60.5311 21.4403 55.0176V48.1237H30.4047C38.8454 48.1237 45.3773 45.945 49.9971 41.5875C54.6184 37.2285 56.9291 31.1251 56.9291 23.2745C56.9291 15.8567 54.6184 10.1236 49.9971 6.07351H49.9986Z" fill="url(#paint1_linear_11_198)"/>
                                <path d="M49.8939 41.6816C50.1784 40.2981 50.3332 38.8676 50.3332 37.3994C50.3332 25.9677 41.2577 16.6692 29.9435 16.3445C31.9697 16.459 33.5596 17.1115 34.704 18.3146C35.9657 19.6448 36.5989 21.5129 36.5989 23.9238C36.5989 26.3347 35.9657 28.3284 34.704 29.7197C33.4408 31.111 31.6382 31.8059 29.2978 31.8059H8.91755C3.99289 31.8059 0 35.812 0 40.753V65.0015H11.4909C16.9862 65.0015 21.4403 60.5327 21.4403 55.0192V48.1253H30.4047C38.7829 48.1253 45.2772 45.9763 49.8923 41.6816H49.8939Z" fill="url(#paint2_linear_11_198)"/>
                                <path d="M21.4418 48.1426C21.3042 48.1394 21.1667 48.1316 21.0291 48.1316C10.7592 48.1316 2.17466 55.3486 0.0234375 65H11.4909C16.9862 65 21.4403 60.5311 21.4403 55.0176V48.141L21.4418 48.1426Z" fill="url(#paint3_linear_11_198)"/>
                                <path d="M69.5347 40.353V17.4048H80.0234C82.6374 17.4048 84.7073 18.0667 86.2316 19.3875C87.7559 20.7098 88.5189 22.4964 88.5189 24.7473C88.5189 26.9982 87.6965 28.813 86.0518 30.1886C84.4072 31.5658 82.245 32.2544 79.5654 32.2544H73.7167V40.3514H69.5347V40.353ZM84.2711 24.8131C84.2711 23.6555 83.8897 22.7473 83.1283 22.0917C82.3654 21.436 81.2991 21.1082 79.9265 21.1082H73.7183V28.5824H79.7311C81.1256 28.5824 82.2309 28.2436 83.047 27.566C83.8631 26.8884 84.2727 25.9707 84.2727 24.8116L84.2711 24.8131Z" fill="#232528"/>
                                <path d="M90.7732 17.4048H94.6942V40.353H90.7732V17.4048Z" fill="#232528"/>
                                <path d="M113.449 40.353H109.626V38.4189C108.994 39.1844 108.238 39.7632 107.354 40.1569C106.473 40.5506 105.475 40.7467 104.365 40.7467C102.273 40.7467 100.64 40.0863 99.464 38.764C98.2884 37.4417 97.699 35.6112 97.699 33.2724V24.159H101.62V32.9117C101.62 34.2889 101.951 35.3712 102.616 36.157C103.28 36.9445 104.212 37.3366 105.41 37.3366C106.607 37.3366 107.648 36.91 108.399 36.0582C109.151 35.2065 109.526 34.0912 109.526 32.714V24.1575H113.447V40.353H113.449Z" fill="#232528"/>
                                <path d="M116.389 24.1574H120.179V25.7636C120.745 25.1079 121.398 24.6107 122.139 24.2719C122.879 23.9331 123.697 23.7637 124.589 23.7637C125.635 23.7637 126.572 23.9597 127.399 24.3535C128.226 24.7472 128.901 25.315 129.425 26.0585C130.056 25.315 130.813 24.7472 131.696 24.3535C132.578 23.9597 133.565 23.7637 134.654 23.7637C136.615 23.7637 138.161 24.3597 139.294 25.5503C140.426 26.7424 140.994 28.3423 140.994 30.3532V40.3529H137.073V30.714C137.073 29.5988 136.801 28.7313 136.255 28.107C135.711 27.4843 134.948 27.1722 133.968 27.1722C132.988 27.1722 132.159 27.5157 131.549 28.2043C130.94 28.8929 130.635 29.7948 130.635 30.9085V40.3497H126.714V30.7109C126.714 29.5956 126.442 28.7282 125.896 28.1039C125.352 27.4812 124.589 27.169 123.609 27.169C122.629 27.169 121.806 27.5126 121.208 28.2012C120.609 28.8898 120.309 29.7917 120.309 30.9054V40.3466H116.388V24.1511L116.389 24.1574Z" fill="#232528"/>
                                <path d="M147.562 40.353H143.772V17.4048H147.693V25.9284C148.412 25.2288 149.213 24.6986 150.094 24.3379C150.976 23.9771 151.93 23.7967 152.954 23.7967C155.219 23.7967 157.109 24.5841 158.623 26.1574C160.136 27.7307 160.894 29.7416 160.894 32.1901C160.894 34.6387 160.12 36.7311 158.574 38.3374C157.028 39.9436 155.111 40.7467 152.824 40.7467C151.8 40.7467 150.835 40.5553 149.932 40.1726C149.028 39.7899 148.237 39.2267 147.563 38.4848V40.353H147.562ZM152.332 27.1409C150.981 27.1409 149.849 27.6319 148.933 28.6154C148.018 29.5989 147.56 30.8239 147.56 32.2874C147.56 33.7509 148.012 34.9649 148.916 35.9265C149.819 36.888 150.935 37.3696 152.264 37.3696C153.593 37.3696 154.786 36.899 155.646 35.9594C156.506 35.0198 156.936 33.7854 156.936 32.2544C156.936 30.7235 156.506 29.4906 155.646 28.5495C154.784 27.6099 153.679 27.1393 152.33 27.1393L152.332 27.1409Z" fill="#232528"/>
                                <path d="M163.31 17.4048H167.492V21.3717H163.31V17.4048ZM163.441 40.353V24.1575H167.362V40.353H163.441Z" fill="#232528"/>
                                <path d="M170.759 17.4048H174.68V40.353H170.759V17.4048Z" fill="#232528"/>
                                <path d="M178.079 17.4048H182V40.353H178.079V17.4048Z" fill="#232528"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_11_198" x1="0" y1="32.5008" x2="56.9307" y2="32.5008" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#ED1E79"/>
                                    <stop offset="1" stopColor="#6E0063"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_11_198" x1="33.5049" y1="49.2766" x2="18.0761" y2="22.6411" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#540078"/>
                                    <stop offset="1" stopColor="#E72FEE"/>
                                </linearGradient>
                                <linearGradient id="paint2_linear_11_198" x1="17.5521" y1="46.5646" x2="36.1412" y2="28.4997" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#AA2FEE"/>
                                    <stop offset="0.45" stopColor="#7A17B1"/>
                                    <stop offset="0.81" stopColor="#590687"/>
                                    <stop offset="1" stopColor="#4D0078"/>
                                </linearGradient>
                                <linearGradient id="paint3_linear_11_198" x1="13.7578" y1="63.8" x2="7.23627" y2="48.3227" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#B52FEE"/>
                                    <stop offset="0.26" stopColor="#9123D1"/>
                                    <stop offset="0.82" stopColor="#39078A"/>
                                    <stop offset="0.97" stopColor="#220078"/>
                                </linearGradient>
                                <clipPath id="clip0_11_198">
                                    <rect width="182" height="65" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        :
                        <svg width={window.innerWidth > 520 ? "182" : '150'} height={window.innerWidth > 520 ? "65" : '60'} viewBox="0 0 310 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M154.581 91.8657H153.397V90.6817C153.109 91.0977 152.709 91.4337 152.213 91.6577C151.717 91.8976 151.189 92.0096 150.613 92.0096C149.813 92.0096 149.157 91.8017 148.661 91.4017C148.165 91.0017 147.909 90.4577 147.909 89.8018C147.909 89.0658 148.197 88.5219 148.757 88.1379C149.317 87.7699 150.149 87.5779 151.253 87.5779H153.381C153.365 86.89 153.189 86.378 152.853 85.9941C152.501 85.6261 152.021 85.4341 151.381 85.4341C150.997 85.4341 150.629 85.5141 150.261 85.6741C149.893 85.8341 149.525 86.0741 149.157 86.41L148.517 85.5941C148.933 85.1941 149.397 84.8901 149.893 84.6822C150.389 84.4742 150.933 84.3782 151.493 84.3782C152.469 84.3782 153.221 84.6662 153.765 85.2421C154.309 85.8181 154.581 86.618 154.581 87.626V91.8657ZM153.381 88.5379H151.269C150.533 88.5379 149.989 88.6339 149.653 88.8419C149.301 89.0498 149.125 89.3538 149.125 89.7858C149.125 90.1538 149.285 90.4417 149.589 90.6817C149.893 90.9217 150.293 91.0497 150.773 91.0497C151.493 91.0497 152.101 90.8097 152.597 90.3458C153.077 89.8818 153.349 89.2738 153.381 88.5379Z" fill="white"/>
                            <path d="M162.005 81.3542H163.237V91.8655H162.021V90.6976C161.685 91.1136 161.285 91.4495 160.821 91.6735C160.357 91.8975 159.861 92.0255 159.333 92.0255C158.325 92.0255 157.477 91.6735 156.789 90.9696C156.101 90.2656 155.765 89.3697 155.765 88.2658C155.765 87.1618 156.117 86.2499 156.821 85.53C157.525 84.81 158.389 84.442 159.429 84.442C159.925 84.442 160.405 84.538 160.853 84.746C161.301 84.954 161.685 85.242 162.021 85.626V81.3542H162.005ZM159.461 90.9536C160.197 90.9536 160.805 90.6976 161.301 90.1696C161.797 89.6417 162.037 89.0017 162.037 88.2178C162.037 87.4338 161.797 86.7939 161.317 86.2819C160.837 85.7699 160.229 85.514 159.509 85.514C158.773 85.514 158.165 85.7699 157.685 86.2659C157.221 86.7619 156.981 87.4178 156.981 88.2338C156.981 89.0497 157.205 89.7057 157.669 90.2016C158.149 90.6976 158.741 90.9536 159.461 90.9536Z" fill="white"/>
                            <path d="M167.316 91.8655L164.212 84.6021H165.556L167.908 90.3936L170.244 84.6021H171.508L168.436 91.8655H167.316Z" fill="white"/>
                            <path d="M175.556 91.0657C176.036 91.0657 176.484 90.9697 176.884 90.7617C177.284 90.5537 177.668 90.2497 178.004 89.8178L178.739 90.4897C178.323 91.0337 177.844 91.4336 177.3 91.7056C176.756 91.9776 176.132 92.1056 175.46 92.1056C174.372 92.1056 173.476 91.7536 172.772 91.0337C172.068 90.3137 171.732 89.4018 171.732 88.2819C171.732 87.1939 172.084 86.282 172.804 85.5461C173.508 84.8101 174.388 84.4421 175.428 84.4421C176.484 84.4421 177.348 84.7941 178.019 85.4821C178.675 86.17 179.011 87.082 179.011 88.1859C179.011 88.2659 179.011 88.3619 178.995 88.4579C178.979 88.5698 178.979 88.6338 178.963 88.6978H172.996C173.076 89.4018 173.348 89.9778 173.828 90.4257C174.292 90.8417 174.868 91.0657 175.556 91.0657ZM175.428 85.4661C174.804 85.4661 174.26 85.674 173.812 86.074C173.364 86.49 173.092 87.034 172.98 87.7219H177.764C177.668 87.018 177.412 86.474 176.996 86.058C176.58 85.674 176.052 85.4661 175.428 85.4661Z" fill="white"/>
                            <path d="M180.227 84.6181H181.427V85.53C181.651 85.21 181.939 84.97 182.291 84.8101C182.643 84.6501 183.059 84.5701 183.539 84.5701H184.195V85.722H183.523C182.835 85.722 182.307 85.898 181.971 86.2659C181.619 86.6339 181.459 87.1779 181.459 87.8978V91.8655H180.243V84.6181H180.227Z" fill="white"/>
                            <path d="M185.587 89.9297V85.69H184.595V84.602H185.587V82.3782H186.803V84.602H188.483V85.69H186.803V89.8176C186.803 90.1376 186.883 90.3776 187.027 90.5376C187.171 90.6976 187.395 90.7776 187.699 90.7776H188.499V91.8815H187.523C186.851 91.8815 186.371 91.7215 186.051 91.4175C185.747 91.0976 185.587 90.6016 185.587 89.9297Z" fill="white"/>
                            <path d="M189.795 82.186H191.155V83.5299H189.795V82.186ZM189.859 91.8654V84.6019H191.091V91.8654H189.859Z" fill="white"/>
                            <path d="M198.658 89.7539C198.658 90.4418 198.37 90.9858 197.794 91.4178C197.218 91.8497 196.45 92.0577 195.522 92.0577C194.914 92.0577 194.338 91.9617 193.795 91.7698C193.267 91.5778 192.771 91.2738 192.339 90.8898L192.963 89.9619C193.427 90.3458 193.859 90.6178 194.258 90.7938C194.658 90.9698 195.09 91.0498 195.538 91.0498C196.114 91.0498 196.594 90.9378 196.946 90.7138C197.298 90.4898 197.474 90.2018 197.474 89.8339C197.474 89.4659 197.33 89.1939 197.026 89.0339C196.722 88.8739 196.226 88.778 195.538 88.778C194.53 88.746 193.795 88.554 193.331 88.218C192.867 87.882 192.627 87.3701 192.627 86.6661C192.627 86.0262 192.899 85.4982 193.427 85.0662C193.954 84.6343 194.658 84.4263 195.49 84.4263C196.066 84.4263 196.594 84.5223 197.09 84.6982C197.586 84.8742 198.05 85.1462 198.45 85.4982L197.858 86.4101C197.442 86.0742 197.058 85.8342 196.674 85.6902C196.29 85.5462 195.89 85.4662 195.458 85.4662C194.962 85.4662 194.562 85.5782 194.242 85.8022C193.923 86.0262 193.763 86.2981 193.763 86.6181C193.763 86.9701 193.907 87.2261 194.178 87.3861C194.466 87.546 194.898 87.642 195.522 87.674C196.61 87.706 197.41 87.882 197.89 88.218C198.418 88.522 198.658 89.0499 198.658 89.7539Z" fill="white"/>
                            <path d="M199.97 82.186H201.33V83.5299H199.97V82.186ZM200.034 91.8654V84.6019H201.266V91.8654H200.034Z" fill="white"/>
                            <path d="M202.978 84.6182H204.177V85.7382C204.465 85.3222 204.833 85.0022 205.281 84.7622C205.729 84.5383 206.225 84.4263 206.769 84.4263C207.729 84.4263 208.481 84.7302 209.025 85.3542C209.569 85.9781 209.841 86.8261 209.841 87.914V91.8657H208.625V87.994C208.625 87.2261 208.433 86.6341 208.065 86.1861C207.697 85.7382 207.169 85.5302 206.497 85.5302C205.809 85.5302 205.249 85.7702 204.833 86.2341C204.417 86.6981 204.209 87.3221 204.209 88.074V91.8817H202.994V84.6182H202.978Z" fill="white"/>
                            <path d="M217.281 91.0177V90.5057C216.945 90.9057 216.545 91.2097 216.081 91.4336C215.617 91.6576 215.121 91.7696 214.593 91.7696C213.569 91.7696 212.721 91.4336 212.033 90.7457C211.345 90.0577 211.009 89.1938 211.009 88.1219C211.009 87.066 211.361 86.186 212.049 85.4821C212.737 84.7941 213.601 84.4421 214.625 84.4421C215.153 84.4421 215.633 84.5541 216.097 84.7781C216.561 85.0021 216.961 85.3061 217.281 85.7061V84.6181H218.497V90.9697C218.497 92.1696 218.177 93.0655 217.537 93.6735C216.897 94.2814 215.937 94.5694 214.641 94.5694H212.801V93.5135H214.689C215.537 93.5135 216.177 93.3055 216.609 92.8895C217.073 92.4736 217.281 91.8496 217.281 91.0177ZM214.785 90.6817C215.505 90.6817 216.113 90.4417 216.609 89.9458C217.105 89.4498 217.345 88.8418 217.345 88.1219C217.345 87.3859 217.105 86.762 216.609 86.266C216.113 85.77 215.505 85.5301 214.785 85.5301C214.033 85.5301 213.425 85.77 212.961 86.234C212.497 86.698 212.273 87.3379 212.273 88.1059C212.273 88.8738 212.513 89.4978 212.977 89.9777C213.441 90.4417 214.033 90.6817 214.785 90.6817Z" fill="white"/>
                            <path d="M226.304 92.0897C225.2 92.0897 224.288 91.7377 223.6 91.0338C222.896 90.3298 222.56 89.4019 222.56 88.266C222.56 87.1781 222.928 86.2661 223.664 85.5302C224.4 84.7942 225.296 84.4263 226.352 84.4263C227.12 84.4263 227.776 84.6022 228.32 84.9542C228.864 85.3062 229.28 85.8182 229.552 86.4901L228.512 86.9861C228.288 86.4901 227.984 86.1062 227.616 85.8662C227.248 85.6102 226.816 85.4982 226.32 85.4982C225.616 85.4982 225.008 85.7702 224.528 86.2981C224.048 86.8261 223.792 87.4981 223.792 88.266C223.792 89.0499 224.032 89.7219 224.528 90.2338C225.008 90.7618 225.616 91.0178 226.352 91.0178C226.816 91.0178 227.264 90.8898 227.664 90.6338C228.064 90.3778 228.4 90.0259 228.64 89.5619L229.584 90.0739C229.296 90.6978 228.864 91.1778 228.272 91.5458C227.696 91.9137 227.04 92.0897 226.304 92.0897Z" fill="white"/>
                            <path d="M238.031 88.2496C238.031 89.3376 237.663 90.2495 236.927 90.9854C236.191 91.7214 235.295 92.0894 234.223 92.0894C233.151 92.0894 232.256 91.7214 231.536 90.9854C230.816 90.2495 230.464 89.3376 230.464 88.2496C230.464 87.1617 230.832 86.2498 231.568 85.5138C232.303 84.7779 233.199 84.4099 234.271 84.4099C235.343 84.4099 236.239 84.7779 236.959 85.5138C237.679 86.2498 238.031 87.1617 238.031 88.2496ZM231.728 88.2656C231.728 89.0496 231.968 89.7055 232.463 90.2335C232.943 90.7614 233.551 91.0334 234.271 91.0334C234.975 91.0334 235.583 90.7614 236.063 90.2335C236.543 89.7055 236.799 89.0496 236.799 88.2496C236.799 87.4817 236.559 86.8257 236.063 86.2818C235.567 85.7538 234.975 85.4818 234.255 85.4818C233.551 85.4818 232.943 85.7538 232.463 86.2818C231.968 86.8097 231.728 87.4817 231.728 88.2656Z" fill="white"/>
                            <path d="M239.295 84.6181H240.495V85.4821C240.767 85.1301 241.071 84.8741 241.423 84.6981C241.775 84.5221 242.159 84.4421 242.591 84.4421C243.087 84.4421 243.535 84.5541 243.935 84.7621C244.319 84.9701 244.639 85.2741 244.863 85.674C245.134 85.2741 245.47 84.9701 245.886 84.7621C246.302 84.5541 246.766 84.4421 247.294 84.4421C248.142 84.4421 248.83 84.7141 249.326 85.2741C249.838 85.818 250.078 86.538 250.078 87.4179V91.8816H248.846V87.4979C248.846 86.89 248.686 86.41 248.382 86.058C248.078 85.706 247.646 85.5461 247.102 85.5461C246.558 85.5461 246.126 85.7381 245.79 86.106C245.454 86.474 245.294 86.97 245.294 87.5779V91.8816H244.079V87.4979C244.079 86.89 243.919 86.41 243.615 86.058C243.311 85.706 242.879 85.5461 242.335 85.5461C241.791 85.5461 241.359 85.7381 241.023 86.106C240.687 86.474 240.527 86.97 240.527 87.5779V91.8816H239.311V84.6181H239.295Z" fill="white"/>
                            <path d="M252.798 94.5694H251.582V84.6021H252.782V85.7701C253.102 85.3541 253.502 85.0181 253.966 84.7941C254.43 84.5541 254.942 84.4421 255.47 84.4421C256.494 84.4421 257.342 84.7941 258.03 85.4981C258.718 86.202 259.054 87.1139 259.054 88.2019C259.054 89.3058 258.702 90.2337 257.998 90.9377C257.294 91.6576 256.43 92.0096 255.39 92.0096C254.894 92.0096 254.43 91.9136 253.966 91.7056C253.518 91.4976 253.118 91.2097 252.798 90.8257V94.5694ZM255.342 85.5301C254.606 85.5301 253.998 85.786 253.502 86.314C253.006 86.842 252.766 87.4979 252.766 88.2819C252.766 89.0498 253.006 89.6898 253.502 90.2017C253.982 90.7137 254.59 90.9697 255.31 90.9697C256.046 90.9697 256.654 90.7297 257.118 90.2337C257.582 89.7378 257.822 89.0818 257.822 88.2659C257.822 87.4499 257.598 86.794 257.134 86.298C256.654 85.786 256.062 85.5301 255.342 85.5301Z" fill="white"/>
                            <path d="M266.541 91.8657H265.357V90.6817C265.069 91.0977 264.669 91.4337 264.173 91.6577C263.677 91.8976 263.149 92.0096 262.573 92.0096C261.773 92.0096 261.117 91.8017 260.621 91.4017C260.125 91.0017 259.869 90.4577 259.869 89.8018C259.869 89.0658 260.157 88.5219 260.717 88.1379C261.277 87.7699 262.109 87.5779 263.213 87.5779H265.341C265.325 86.89 265.149 86.378 264.813 85.9941C264.461 85.6261 263.981 85.4341 263.341 85.4341C262.957 85.4341 262.589 85.5141 262.221 85.6741C261.853 85.8341 261.485 86.0741 261.117 86.41L260.477 85.5941C260.893 85.1941 261.357 84.8901 261.853 84.6822C262.349 84.4742 262.893 84.3782 263.453 84.3782C264.429 84.3782 265.181 84.6662 265.725 85.2421C266.269 85.8181 266.541 86.618 266.541 87.626V91.8657ZM265.341 88.5379H263.229C262.493 88.5379 261.949 88.6339 261.613 88.8419C261.261 89.0498 261.085 89.3538 261.085 89.7858C261.085 90.1538 261.245 90.4417 261.549 90.6817C261.853 90.9217 262.253 91.0497 262.733 91.0497C263.453 91.0497 264.061 90.8097 264.557 90.3458C265.037 89.8818 265.309 89.2738 265.341 88.5379Z" fill="white"/>
                            <path d="M268.045 84.6182H269.245V85.7382C269.533 85.3222 269.901 85.0022 270.349 84.7622C270.797 84.5383 271.293 84.4263 271.837 84.4263C272.797 84.4263 273.549 84.7302 274.093 85.3542C274.637 85.9781 274.909 86.8261 274.909 87.914V91.8657H273.693V87.994C273.693 87.2261 273.501 86.6341 273.133 86.1861C272.765 85.7382 272.237 85.5302 271.565 85.5302C270.877 85.5302 270.317 85.7702 269.901 86.2341C269.485 86.6981 269.277 87.3221 269.277 88.074V91.8817H268.061V84.6182H268.045Z" fill="white"/>
                            <path d="M278.572 91.8177L275.5 84.6182H276.844L279.18 90.3938L281.516 84.6182H282.796L278.572 94.5855H277.308L278.572 91.8177Z" fill="white"/>
                            <path d="M119.288 69.6592V30.8779H137.078C141.51 30.8779 145.014 31.9979 147.606 34.2377C150.197 36.4775 151.477 39.4853 151.477 43.293C151.477 47.1008 150.085 50.1566 147.302 52.4924C144.518 54.8122 140.854 55.9801 136.31 55.9801H126.391V69.6592H119.288ZM144.278 43.389C144.278 41.4372 143.638 39.9013 142.342 38.7974C141.046 37.6934 139.238 37.1335 136.918 37.1335H126.391V49.7726H136.582C138.95 49.7726 140.822 49.1966 142.198 48.0607C143.59 46.9088 144.278 45.3569 144.278 43.389Z" fill="#FDFEFF"/>
                            <path d="M155.301 30.8779H161.956V69.6592H155.301V30.8779Z" fill="#FDFEFF"/>
                            <path d="M193.746 69.6592H187.267V66.3955C186.195 67.6914 184.915 68.6673 183.411 69.3392C181.923 70.0112 180.227 70.3312 178.339 70.3312C174.788 70.3312 172.02 69.2112 170.036 66.9714C168.036 64.7316 167.044 61.6438 167.044 57.6921V42.2852H173.7V57.0841C173.7 59.4039 174.26 61.2438 175.396 62.5717C176.516 63.8996 178.099 64.5716 180.131 64.5716C182.243 64.5716 183.923 63.8516 185.203 62.4117C186.483 60.9718 187.107 59.084 187.107 56.7641V42.2852H193.762V69.6592H193.746Z" fill="#FDFEFF"/>
                            <path d="M198.738 42.285H205.17V45.0048C206.13 43.9009 207.233 43.0529 208.497 42.477C209.745 41.901 211.137 41.613 212.657 41.613C214.433 41.613 216.017 41.949 217.425 42.605C218.833 43.2769 219.969 44.2369 220.864 45.4848C221.936 44.2369 223.216 43.2609 224.72 42.605C226.224 41.933 227.888 41.613 229.728 41.613C233.056 41.613 235.679 42.621 237.599 44.6368C239.519 46.6527 240.479 49.3565 240.479 52.7483V69.6431H233.824V53.3722C233.824 51.4843 233.36 50.0124 232.432 48.9725C231.504 47.9166 230.208 47.3886 228.56 47.3886C226.864 47.3886 225.488 47.9646 224.464 49.1325C223.424 50.3004 222.912 51.8203 222.912 53.7082V69.659H216.257V53.3722C216.257 51.4843 215.793 50.0124 214.865 48.9725C213.937 47.9166 212.641 47.3886 210.993 47.3886C209.297 47.3886 207.937 47.9646 206.913 49.1325C205.89 50.3004 205.394 51.8203 205.394 53.7082V69.659H198.738V42.285Z" fill="#FDFEFF"/>
                            <path d="M251.598 69.6592H245.167V30.8779H251.822V45.2769C253.038 44.093 254.398 43.1971 255.902 42.5891C257.39 41.9811 259.006 41.6772 260.749 41.6772C264.589 41.6772 267.789 43.0051 270.365 45.6609C272.925 48.3167 274.22 51.7245 274.22 55.8522C274.22 60.0599 272.909 63.5316 270.285 66.2354C267.661 68.9552 264.413 70.3151 260.541 70.3151C258.798 70.3151 257.166 69.9952 255.646 69.3392C254.11 68.6992 252.766 67.7393 251.63 66.4914V69.6592H251.598ZM259.677 47.3248C257.39 47.3248 255.47 48.1567 253.918 49.8206C252.366 51.4845 251.598 53.5483 251.598 56.0282C251.598 58.508 252.366 60.5558 253.902 62.1717C255.438 63.8036 257.326 64.6035 259.581 64.6035C261.949 64.6035 263.853 63.8036 265.309 62.2197C266.765 60.6358 267.501 58.54 267.501 55.9642C267.501 53.3723 266.765 51.2925 265.309 49.7086C263.837 48.1247 261.965 47.3248 259.677 47.3248Z" fill="#FDFEFF"/>
                            <path d="M278.3 30.8779H285.388V37.5814H278.3V30.8779ZM278.508 69.6592V42.2851H285.164V69.6592H278.508Z" fill="#FDFEFF"/>
                            <path d="M290.923 30.8779H297.579V69.6592H290.923V30.8779Z" fill="#FDFEFF"/>
                            <path d="M303.339 30.8779H309.994V69.6592H303.339V30.8779Z" fill="#FDFEFF"/>
                            <path d="M20.8945 113H0V70.507C0 61.4037 7.40748 53.9962 16.5108 53.9962H51.2124C54.8441 53.9962 57.5319 52.9722 59.4358 50.8924C61.3397 48.7965 62.3156 45.7888 62.3156 41.949C62.3156 38.2373 61.3557 35.3415 59.4518 33.3416C57.5479 31.3418 54.8441 30.3658 51.2124 30.3658H29.0379C13.0231 30.3658 0 17.3428 0 1.3279V0H53.1162C67.7552 0 79.2744 3.58375 87.3538 10.6392C95.4972 17.7427 99.609 27.902 99.609 40.8451C99.609 54.4921 95.4972 65.2754 87.3858 72.9068C79.2904 80.5063 67.7712 84.362 53.1002 84.362H39.1652V94.7293C39.1812 104.793 30.9738 113 20.8945 113ZM2.65581 110.328H20.8945C29.5019 110.328 36.5094 103.321 36.5094 94.7133V81.6742H53.1002C60.1077 81.6742 66.4113 80.7623 71.8029 78.9704C77.1465 77.1945 81.7862 74.4907 85.5619 70.939C89.3377 67.4032 92.2015 63.0835 94.0893 58.1239C95.9932 53.1162 96.9531 47.2927 96.9531 40.8291C96.9531 34.7335 95.9932 29.2619 94.1053 24.5903C92.2335 19.9506 89.3697 15.9189 85.6099 12.6391C78.0265 6.01557 67.0993 2.65581 53.1162 2.65581H2.68781C3.37576 16.5748 14.9269 27.694 29.0219 27.694H51.2124C55.5641 27.694 58.9718 28.9739 61.3877 31.4858C63.7715 33.9976 64.9874 37.5013 64.9874 41.933C64.9874 46.4447 63.7875 50.0605 61.4197 52.6683C59.0198 55.3081 55.5801 56.652 51.2284 56.652H16.5108C8.86337 56.652 2.65581 62.8755 2.65581 70.507V110.328Z" fill="white"/>
                            <path d="M20.8945 113H0V70.507C0 61.4037 7.40748 53.9962 16.5108 53.9962H51.2124C54.8441 53.9962 57.5319 52.9723 59.4358 50.8924C61.3397 48.7966 62.3156 45.7888 62.3156 41.9491C62.3156 38.2373 61.3557 35.3415 59.4518 33.3417C57.7399 31.5498 55.3721 30.5899 52.2363 30.4139L52.3483 27.7581C62.0276 28.03 71.083 32.0138 77.8505 38.9573C84.634 45.9168 88.3617 55.1001 88.3617 64.8274C88.3617 67.3393 88.1058 69.8671 87.5778 72.3629L87.4978 72.7789L87.1778 73.0669C79.0984 80.5543 67.6272 84.3621 53.0842 84.3621H39.1492V94.7293C39.1812 104.793 30.9738 113 20.8945 113ZM2.65581 110.328H20.8945C29.5019 110.328 36.5094 103.321 36.5094 94.7133V81.6742H53.1002C60.0598 81.6742 66.3153 80.7783 71.6749 79.0024C76.8426 77.2906 81.3542 74.7307 85.066 71.371C85.482 69.1951 85.7059 66.9713 85.7059 64.7954C85.7059 55.7721 82.2342 47.2447 75.9466 40.7811C71.9789 36.7014 67.1472 33.7256 61.8676 32.0298C63.9315 34.4936 64.9714 37.8053 64.9714 41.9171C64.9714 46.4287 63.7715 50.0445 61.4037 52.6523C59.0038 55.2921 55.5641 56.636 51.2124 56.636H16.5108C8.86337 56.636 2.65581 62.8596 2.65581 70.491V110.328Z" fill="white"/>
                            <path d="M21.2467 112.952H0.064209L0.432183 111.336C2.28805 103.049 6.95972 95.5133 13.5993 90.1377C20.3348 84.6821 28.8302 81.6743 37.5016 81.6743C37.6615 81.6743 37.8055 81.6743 37.9655 81.6903C38.0455 81.6903 38.1415 81.6903 38.2215 81.6903L39.5334 81.7223V94.6974C39.5334 104.745 31.326 112.952 21.2467 112.952ZM3.42397 110.28H21.2467C29.8541 110.28 36.8616 103.273 36.8616 94.6654V84.3141C29.0062 84.4581 21.3587 87.2419 15.2631 92.1856C9.53554 96.8413 5.37583 103.225 3.42397 110.28Z" fill="white"/>
                        </svg>
                    }
                </div>
            </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" bsPrefix={'btn-toggle d-md-none'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-list" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </Navbar.Toggle>
            <Navbar.Collapse className={`justify-content-end text-end ${window.innerWidth < 992 && 'shadow'}`} id="basic-navbar-nav">
                <Nav className={''} style={{marginRight: window.innerWidth > 992 ? '30%' : '0'}}>
                    <Nav.Link onClick={() => scroll(aboutRef)} className={`me-lg-3 ${window.innerWidth < 992 ? 'p-3 border bg-white rounded-top text-center text-black start bg-opacity-75' : 'text-white'}`}>About Us</Nav.Link>
                    <Nav.Link onClick={() => scroll(productsRef)} className={`me-lg-3 ${window.innerWidth < 992 ? 'p-3 border bg-white text-center text-black start bg-opacity-75' : 'text-white'}`}>Our products</Nav.Link>
                    <Nav.Link onClick={() => scroll(contactRef)} className={`me-lg-3 ${window.innerWidth < 992 ? 'p-3 border bg-white rounded-bottom text-center text-black start bg-opacity-75' : 'text-white'}`}>Contacts</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;